'use client';

import { Modal, ModalBody, ModalOverlay, ModalContent } from '@chakra-ui/react';
import Image from 'next/image';

import useHook from './hook';

const Component: React.FC = () => {
  const { close, showDetail, isModalOpen, modalProps } = useHook();

  return (
    <Modal isCentered isOpen={isModalOpen} onClose={close}>
      <ModalOverlay bg="rgba(0, 0, 0, 0.25)" />

      <ModalContent className="!max-w-[calc(100%_-_20px)] sm:!max-w-[480px]">
        <ModalBody>
          {modalProps && (
            <div className="py-4">
              <div className="relative mb-2 aspect-video overflow-hidden rounded border border-gray-300 bg-gray-200">
                <Image
                  fill
                  alt={modalProps.title}
                  src={modalProps.imageUrl}
                  className="block h-full w-full object-cover"
                />
              </div>

              <p className="mb-5 text-center text-lg font-bold leading-normal text-gray-700">
                {modalProps.title}
              </p>

              <div className="flex flex-col items-center gap-y-4">
                <button
                  type="button"
                  onClick={showDetail}
                  className="block w-full max-w-[240px] rounded bg-blue-400 py-3 text-center text-base font-bold text-white active:bg-blue-500"
                >
                  詳しくはこちら
                </button>

                <button
                  onClick={close}
                  className="mx-auto block px-4 py-1 text-sm text-gray-500 underline outline-none"
                >
                  閉じる
                </button>
              </div>
            </div>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Component;

import { orderBy } from 'lodash-es';
import { usePathname, useRouter } from 'next/navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { logEvent } from '@/utils/analytics';
import { dayjs } from '@/utils/dayjs';

import { listPostsForNoticeModal } from './actions';

type ModalProps = {
  id: number;
  title: string;
  link: string;
  imageUrl: string;
};

type Log = {
  postId: number;
  displayedAt: string;
};

const localStorageKey = 'noticeModalLogs';

const useHook = () => {
  const router = useRouter();
  const pathname = usePathname();
  const [modalProps, setModalProps] = useState<ModalProps | null>(null);

  const isModalOpen = useMemo(() => modalProps !== null, [modalProps]);

  const showDetail = useCallback(() => {
    if (!modalProps) {
      return;
    }

    logEvent({
      name: 'click_show_detail_modal_button',
      params: {
        postId: modalProps.id,
      },
    });

    router.push(modalProps.link);

    setModalProps(null);
  }, [router, modalProps]);

  const close = useCallback(() => {
    if (!modalProps) {
      return;
    }

    logEvent({
      name: 'click_close_modal_button',
      params: {
        postId: modalProps.id,
      },
    });

    setModalProps(null);
  }, [modalProps]);

  useEffect(() => {
    const main = async () => {
      if (['/help', '/info'].includes(pathname)) {
        return;
      }

      let items: Log[] = [];

      const data = localStorage.getItem(localStorageKey);

      if (data) {
        try {
          items = JSON.parse(data);
        } catch {
          //
        }

        const today = dayjs();
        const isDisplayedToday = items.some(item =>
          dayjs(item.displayedAt).isSame(today, 'day'),
        );

        if (isDisplayedToday) {
          return;
        }
      }

      const posts = orderBy(
        (await listPostsForNoticeModal())
          .map(post => {
            const item = items.find(i => i.postId === post.id);

            return {
              ...post,
              link: `/blogs/staff/${post.id}`,
              modalLastDisplayedAt:
                item?.displayedAt ??
                dayjs('2020-01-01T00:00:00+09:00').tz().toISOString(),
            };
          })
          .filter(post => pathname !== post.link),
        ['modalLastDisplayedAt'],
        ['asc'],
      );

      const post = posts[0];

      if (!post) {
        return;
      }

      items.push({ postId: post.id, displayedAt: dayjs().tz().toISOString() });
      localStorage.setItem(localStorageKey, JSON.stringify(items));

      setModalProps({
        id: post.id,
        link: post.link,
        title: post.title,
        imageUrl: post.imageUrl,
      });
    };

    void main();
  }, [pathname]);

  return { close, showDetail, isModalOpen, modalProps };
};

export default useHook;

import dayjs, { extend } from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

extend(utc);
extend(timezone);
extend(minMax);

dayjs.tz.setDefault('Asia/Tokyo');

export { dayjs };

/**
 * 入力された時刻をJSTとして解釈する
 * WordPressが返却するデータはタイムゾーン情報なしのJST時刻（例：2024-03-22T11:09:04）であるため、
 * 正しくパースするにはタイムゾーンを指定する必要がある
 */
export const parseAsJst = (date: string) => dayjs.tz(date, 'Asia/Tokyo');

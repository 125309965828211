declare const dataLayer: unknown[];

export type LogEventInput =
  | {
      name: 'click_visit_landing_page_link';
      params: {
        place: 'itagaki_premium_blog';
      };
    }
  | {
      name: 'click_lp_conversion_button';
      params: {
        place:
          | 'first_view'
          | 'header'
          | 'conversion_banner_top'
          | 'conversion_banner_bottom'
          | 'sample_movie';
      };
    }
  | {
      name: 'submit_sign_up_form';
      params: {
        formId: 'first-view' | 'bottom';
      };
    }
  | {
      name: 'click_show_detail_modal_button';
      params: {
        postId: number;
      };
    }
  | {
      name: 'click_close_modal_button';
      params: {
        postId: number;
      };
    }
  | {
      name: 'click_visit_post_page_link';
      params: {
        place:
          | 'sidebar_popular_posts_list'
          | 'sidebar_special_posts_list'
          | 'published_posts_list'
          | 'slide_area'
          | 'recent_posts_list'
          | 'excerpt_tag_posts_list'
          | 'search_results_list'
          | 'coach_area'
          | 'technique_area'
          | 'related_area'
          | 'lesson_courses_list'
          | 'staff_blog_image'
          | 'staff_blog_button';
      };
    };

export type EventParams<T extends LogEventInput['name']> = Extract<
  LogEventInput,
  { name: T }
>['params'];

export const logEvent = ({ name, params }: LogEventInput) => {
  const stringifiedParams = JSON.stringify(params);

  // GA4でのイベントパラメタの長さの上限は100文字
  // 収集と設定の上限（ https://support.google.com/analytics/answer/9267744?hl=ja ）
  const maxEventParamsLength = 100;

  if (stringifiedParams.length > maxEventParamsLength) {
    throw new Error('[analytics] payload is too long');
  }

  const payload: Record<string, string> = {
    event: 'custom_event',
    custom_event_name: name,
    custom_event_params: stringifiedParams,
  };

  // eslint-disable-next-line no-console
  console.info(JSON.stringify(payload, null, 2));

  dataLayer.push(payload);
};
